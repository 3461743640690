var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "columnDesign-container" },
    [
      _c(
        "div",
        { staticClass: "main-board" },
        [
          _c(
            "div",
            { staticClass: "search-box" },
            [
              _c("h4", { staticClass: "cap" }, [_vm._v("查询字段")]),
              _c(
                "el-table",
                {
                  ref: "dragTableSearch",
                  staticClass: "SNOWS-common-table",
                  attrs: {
                    data: _vm.columnData.searchList,
                    size: "medium",
                    "row-key": (row) => row.prop,
                  },
                },
                [
                  _c(
                    "el-table-column",
                    { attrs: { align: "center", label: "拖动", width: "50" } },
                    [
                      [
                        _c("i", {
                          staticClass: "drag-handler icon-ym icon-ym-darg",
                          staticStyle: { cursor: "move", "font-size": "20px" },
                          attrs: { title: "点击拖动" },
                        }),
                      ],
                    ],
                    2
                  ),
                  _c("el-table-column", {
                    attrs: { prop: "label", label: "列名" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "prop", label: "字段" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "searchType", label: "类型" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "请选择",
                                  disabled:
                                    scope.row.jnpfKey !== "comInput" &&
                                    scope.row.jnpfKey !== "textarea",
                                },
                                model: {
                                  value: scope.row.searchType,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "searchType", $$v)
                                  },
                                  expression: "scope.row.searchType",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "等于查询", value: 1 },
                                }),
                                _c("el-option", {
                                  attrs: { label: "模糊查询", value: 2 },
                                }),
                                _c("el-option", {
                                  attrs: { label: "范围查询", value: 3 },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-divider"),
          _c(
            "div",
            { staticClass: "sort-box" },
            [
              _c("h4", { staticClass: "cap" }, [_vm._v("排序字段")]),
              _c(
                "el-table",
                {
                  ref: "dragTableSort",
                  staticClass: "SNOWS-common-table",
                  attrs: {
                    data: _vm.columnData.sortList,
                    size: "medium",
                    "row-key": (row) => row.prop,
                  },
                },
                [
                  _c(
                    "el-table-column",
                    { attrs: { align: "center", label: "拖动", width: "50" } },
                    [
                      [
                        _c("i", {
                          staticClass: "drag-handler icon-ym icon-ym-darg",
                          staticStyle: { cursor: "move", "font-size": "20px" },
                          attrs: { title: "点击拖动" },
                        }),
                      ],
                    ],
                    2
                  ),
                  _c("el-table-column", {
                    attrs: { prop: "label", label: "列名" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "prop", label: "字段" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-divider"),
          _c(
            "div",
            { staticClass: "column-box" },
            [
              _c("h4", { staticClass: "cap" }, [_vm._v("列表字段")]),
              _c(
                "el-table",
                {
                  ref: "dragTable",
                  staticClass: "SNOWS-common-table",
                  attrs: {
                    data: _vm.columnData.columnList,
                    size: "medium",
                    "row-key": (row) => row.prop,
                  },
                },
                [
                  _c(
                    "el-table-column",
                    { attrs: { align: "center", label: "拖动", width: "50" } },
                    [
                      [
                        _c("i", {
                          staticClass: "drag-handler icon-ym icon-ym-darg",
                          staticStyle: { cursor: "move", "font-size": "20px" },
                          attrs: { title: "点击拖动" },
                        }),
                      ],
                    ],
                    2
                  ),
                  _c("el-table-column", {
                    attrs: { prop: "label", label: "列名" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "prop", label: "字段" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "right-board" },
        [
          _c(
            "el-tabs",
            {
              staticClass: "top-tabs top-tabs_app",
              staticStyle: { "margin-bottom": "0px" },
              model: {
                value: _vm.currentTab,
                callback: function ($$v) {
                  _vm.currentTab = $$v
                },
                expression: "currentTab",
              },
            },
            [
              _c("el-tab-pane", {
                attrs: { label: "查询字段", name: "search" },
              }),
              _c("el-tab-pane", { attrs: { label: "排序字段", name: "sort" } }),
              _c("el-tab-pane", {
                attrs: { label: "列表字段", name: "field" },
              }),
              _c("el-tab-pane", {
                attrs: { label: "列表属性", name: "column" },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "field-box" },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === "sort",
                      expression: "currentTab==='sort'",
                    },
                  ],
                  staticClass: "columnList",
                },
                [
                  _c(
                    "el-table",
                    {
                      ref: "sortTable",
                      staticClass: "SNOWS-common-table",
                      attrs: {
                        data: _vm.sortOptions,
                        height: "100%",
                        size: "medium",
                      },
                      on: { "selection-change": _vm.sortSelectionChange },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "label", label: "排序字段" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          type: "selection",
                          width: "55",
                          align: "center",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === "search",
                      expression: "currentTab==='search'",
                    },
                  ],
                  staticClass: "columnList",
                },
                [
                  _c(
                    "el-table",
                    {
                      ref: "searchTable",
                      staticClass: "SNOWS-common-table",
                      attrs: {
                        data: _vm.searchOptions,
                        height: "100%",
                        size: "medium",
                      },
                      on: { "selection-change": _vm.searchSelectionChange },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "label", label: "查询字段" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          type: "selection",
                          width: "55",
                          align: "center",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === "field",
                      expression: "currentTab==='field'",
                    },
                  ],
                  staticClass: "columnList",
                },
                [
                  _c(
                    "el-table",
                    {
                      ref: "columnTable",
                      staticClass: "SNOWS-common-table",
                      attrs: {
                        data: _vm.columnOptions,
                        height: "100%",
                        size: "medium",
                      },
                      on: { "selection-change": _vm.columnSelectionChange },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "label", label: "列表字段" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          type: "selection",
                          width: "55",
                          align: "center",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-scrollbar",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === "column",
                      expression: "currentTab==='column'",
                    },
                  ],
                  staticClass: "right-scrollbar",
                },
                [
                  _c(
                    "div",
                    { staticClass: "setting-box" },
                    [
                      _c(
                        "el-form",
                        {
                          attrs: {
                            model: _vm.columnData,
                            "label-width": "70px",
                            "label-position": "left",
                          },
                        },
                        [
                          _c("el-divider", [_vm._v("表格配置")]),
                          _c(
                            "el-form-item",
                            { attrs: { label: "排序类型" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择排序类型" },
                                  model: {
                                    value: _vm.columnData.sort,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.columnData, "sort", $$v)
                                    },
                                    expression: "columnData.sort",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "升序", value: "asc" },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "降序", value: "desc" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "排序字段" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择排序字段",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.columnData.defaultSidx,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.columnData,
                                        "defaultSidx",
                                        $$v
                                      )
                                    },
                                    expression: "columnData.defaultSidx",
                                  },
                                },
                                _vm._l(
                                  _vm.groupFieldOptions,
                                  function (item, i) {
                                    return _c("el-option", {
                                      key: i,
                                      attrs: {
                                        label: item.__config__.label,
                                        value: item.__vModel__,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "分页设置" } },
                            [
                              _c("el-switch", {
                                model: {
                                  value: _vm.columnData.hasPage,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.columnData, "hasPage", $$v)
                                  },
                                  expression: "columnData.hasPage",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.columnData.hasPage
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "分页条数" } },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value: _vm.columnData.pageSize,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.columnData,
                                            "pageSize",
                                            $$v
                                          )
                                        },
                                        expression: "columnData.pageSize",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio-button",
                                        { attrs: { label: 20 } },
                                        [_vm._v("20条")]
                                      ),
                                      _c(
                                        "el-radio-button",
                                        { attrs: { label: 50 } },
                                        [_vm._v("50条")]
                                      ),
                                      _c(
                                        "el-radio-button",
                                        { attrs: { label: 100 } },
                                        [_vm._v("100条")]
                                      ),
                                      _c(
                                        "el-radio-button",
                                        { attrs: { label: 500 } },
                                        [_vm._v("500条")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("el-divider", [_vm._v("按钮配置")]),
                          _c(
                            "el-checkbox-group",
                            {
                              staticClass: "btnsList",
                              model: {
                                value: _vm.btnsList,
                                callback: function ($$v) {
                                  _vm.btnsList = $$v
                                },
                                expression: "btnsList",
                              },
                            },
                            _vm._l(_vm.btnsOption, function (item) {
                              return _c(
                                "el-checkbox",
                                {
                                  key: item.value,
                                  staticStyle: { "margin-bottom": "12px" },
                                  attrs: { label: item.value },
                                },
                                [
                                  _c("span", { staticClass: "btn-label" }, [
                                    _vm._v(
                                      _vm._s(_vm._f("btnText")(item.value))
                                    ),
                                  ]),
                                  _c("el-input", {
                                    model: {
                                      value: item.label,
                                      callback: function ($$v) {
                                        _vm.$set(item, "label", $$v)
                                      },
                                      expression: "item.label",
                                    },
                                  }),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                          _c(
                            "el-checkbox-group",
                            {
                              staticClass: "btnsList columnBtnList",
                              model: {
                                value: _vm.columnBtnsList,
                                callback: function ($$v) {
                                  _vm.columnBtnsList = $$v
                                },
                                expression: "columnBtnsList",
                              },
                            },
                            _vm._l(_vm.columnBtnsOption, function (item) {
                              return _c(
                                "el-checkbox",
                                {
                                  key: item.value,
                                  staticStyle: { "margin-bottom": "12px" },
                                  attrs: { label: item.value },
                                },
                                [
                                  _c("span", { staticClass: "btn-label" }, [
                                    _vm._v(
                                      _vm._s(_vm._f("btnText")(item.value))
                                    ),
                                  ]),
                                  _c(
                                    "el-input",
                                    {
                                      staticStyle: { width: "auto" },
                                      model: {
                                        value: item.label,
                                        callback: function ($$v) {
                                          _vm.$set(item, "label", $$v)
                                        },
                                        expression: "item.label",
                                      },
                                    },
                                    [
                                      _vm.columnData.useBtnPermission
                                        ? _c(
                                            "template",
                                            { slot: "append" },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  staticClass: "custom-btn",
                                                  attrs: { type: "primary" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.columnFunc(
                                                        item,
                                                        "btn"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "事件\n                    "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                          _vm.modelType == 1
                            ? [
                                _c(
                                  "p",
                                  { staticClass: "btn-cap mt-10 mb-10" },
                                  [_vm._v("自定义按钮区")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "custom-btns-list" },
                                  [
                                    _c(
                                      "draggable",
                                      {
                                        attrs: {
                                          list: _vm.columnData.customBtnsList,
                                          animation: 340,
                                          group: "customItem",
                                          handle: ".option-drag",
                                        },
                                      },
                                      _vm._l(
                                        _vm.columnData.customBtnsList,
                                        function (item, index) {
                                          return _c(
                                            "div",
                                            {
                                              key: index,
                                              staticClass: "custom-item",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "custom-line-icon option-drag",
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "icon-ym icon-ym-darg",
                                                  }),
                                                ]
                                              ),
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "custom-line-value",
                                                },
                                                [_vm._v(_vm._s(item.value))]
                                              ),
                                              _c(
                                                "el-input",
                                                {
                                                  staticStyle: {
                                                    width: "auto",
                                                  },
                                                  attrs: {
                                                    placeholder: "按钮名称",
                                                    size: "small",
                                                  },
                                                  model: {
                                                    value: item.label,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "label",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.label",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "template",
                                                    { slot: "append" },
                                                    [
                                                      _c(
                                                        "el-button",
                                                        {
                                                          staticClass:
                                                            "custom-btn",
                                                          attrs: {
                                                            type: "primary",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.editFunc(
                                                                item,
                                                                "btn"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "事件\n                        "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                2
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "close-btn custom-line-icon",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.columnData.customBtnsList.splice(
                                                        index,
                                                        1
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-remove-outline",
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "text",
                                          icon: "el-icon-plus",
                                        },
                                        on: { click: _vm.addCustomBtn },
                                      },
                                      [_vm._v("添加按钮")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                          _c("el-divider", [_vm._v("权限设置")]),
                          _c(
                            "el-form-item",
                            { attrs: { label: "按钮权限" } },
                            [
                              _c("el-switch", {
                                model: {
                                  value: _vm.columnData.useBtnPermission,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.columnData,
                                      "useBtnPermission",
                                      $$v
                                    )
                                  },
                                  expression: "columnData.useBtnPermission",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "列表权限" } },
                            [
                              _c("el-switch", {
                                model: {
                                  value: _vm.columnData.useColumnPermission,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.columnData,
                                      "useColumnPermission",
                                      $$v
                                    )
                                  },
                                  expression: "columnData.useColumnPermission",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "表单权限" } },
                            [
                              _c("el-switch", {
                                model: {
                                  value: _vm.columnData.useFormPermission,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.columnData,
                                      "useFormPermission",
                                      $$v
                                    )
                                  },
                                  expression: "columnData.useFormPermission",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "数据权限" } },
                            [
                              _c("el-switch", {
                                model: {
                                  value: _vm.columnData.useDataPermission,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.columnData,
                                      "useDataPermission",
                                      $$v
                                    )
                                  },
                                  expression: "columnData.useDataPermission",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.modelType == 1
                            ? [
                                _c("el-divider", [_vm._v("脚本事件")]),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "表格事件" } },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { width: "100%" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.addFunc(
                                              _vm.columnData.funcs.afterOnload,
                                              "afterOnload",
                                              true
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("脚本编写\n                ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.formScriptVisible
        ? _c("form-script", {
            key: _vm.scriptKey,
            ref: "formScript",
            attrs: { value: _vm.activeItem.func, type: _vm.activeItem.type },
            on: {
              updateScript: _vm.updateScript,
              closeDialog: function ($event) {
                _vm.formScriptVisible = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }