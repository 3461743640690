var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    _vm._g(
      _vm._b(
        {
          staticClass: "SNOWS-dialog SNOWS-dialog_center form-script-dialog",
          attrs: {
            "close-on-click-modal": false,
            "lock-scroll": "",
            "append-to-body": "",
            width: "1000px",
            "modal-append-to-body": false,
            "destroy-on-close": "",
            visible: _vm.dialogVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        "el-dialog",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c(
        "span",
        {
          staticClass: "dialog-title",
          attrs: { slot: "title" },
          slot: "title",
        },
        [
          _c(
            "span",
            [
              _vm._v(_vm._s(_vm.title) + "\n      "),
              _c(
                "el-tooltip",
                {
                  attrs: {
                    content: "小程序不支持在线JS脚本",
                    placement: "top-start",
                  },
                },
                [_c("a", { staticClass: "el-icon-warning-outline" })]
              ),
            ],
            1
          ),
        ]
      ),
      _c("div", { staticClass: "form-script-dialog-body" }, [
        _c("div", { staticClass: "right-main" }, [
          _c(
            "div",
            { staticClass: "codeEditor" },
            [
              _c("JNPFCodeEditor", {
                ref: "CodeEditor",
                attrs: { options: _vm.options },
                model: {
                  value: _vm.text,
                  callback: function ($$v) {
                    _vm.text = $$v
                  },
                  expression: "text",
                },
              }),
            ],
            1
          ),
          _vm.type == "btn"
            ? _c("div", { staticClass: "tips" }, [
                _c("p", [_vm._v("支持JavaScript的脚本，参考编写脚本API")]),
                _c("p", [
                  _vm._v(
                    "data--列表当前行数据，index--列表当前行下标，request--异步请求(url,method,data)"
                  ),
                ]),
                _c("p", [_vm._v("toast--消息提示，refresh--刷新页面")]),
              ])
            : _vm._e(),
          _vm.type == "afterOnload"
            ? _c("div", { staticClass: "tips" }, [
                _c("p", [_vm._v("支持JavaScript的脚本，参考编写脚本API")]),
                _c("p", [
                  _vm._v(
                    "data--列表行数据，tableRef--表格DOM元素，request--异步请求(url,methods,data)"
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.type == "rowStyle"
            ? _c("div", { staticClass: "tips" }, [
                _c("p", [_vm._v("支持JavaScript的脚本，需要返回一个Object")]),
                _c("p", [_vm._v("row--列表行数据，rowIndex--列表行下标")]),
              ])
            : _vm._e(),
          _vm.type == "cellStyle"
            ? _c("div", { staticClass: "tips" }, [
                _c("p", [_vm._v("支持JavaScript的脚本，需要返回一个Object")]),
                _c("p", [_vm._v("row--列表行数据，column--列表列数据")]),
                _c("p", [
                  _vm._v("rowIndex--列表行下标，columnIndex--列表列下标"),
                ]),
              ])
            : _vm._e(),
        ]),
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.closeDialog } }, [_vm._v("取消")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.onClose()
                },
              },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }