var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    _vm._g(
      _vm._b(
        {
          staticClass: "SNOWS-dialog SNOWS-dialog_center SNOWS-dialog-export",
          attrs: {
            title: "模板设置",
            "close-on-click-modal": false,
            "lock-scroll": "",
            "append-to-body": "",
            width: "600px",
            "modal-append-to-body": false,
          },
        },
        "el-dialog",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c(
        "div",
        { staticClass: "dialog-main" },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                model: _vm.dataForm,
                "label-width": "100px",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "导入模式" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.dataForm.dataType,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataForm, "dataType", $$v)
                        },
                        expression: "dataForm.dataType",
                      },
                    },
                    [
                      _c(
                        "el-radio",
                        { attrs: { label: "1" } },
                        [
                          _vm._v("仅新增数据\n            "),
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content:
                                  "导入数据只能进行新增，同一条数据无法重复导入",
                                placement: "top-start",
                              },
                            },
                            [
                              _c("a", {
                                staticClass: "el-icon-warning-outline",
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-radio",
                        { attrs: { label: "2" } },
                        [
                          _vm._v("更新和新增数据\n            "),
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: "允许新增数据的同时支持导入数据更新",
                                placement: "top-start",
                              },
                            },
                            [
                              _c("a", {
                                staticClass: "el-icon-warning-outline",
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "upload-line" }, [
                _c(
                  "p",
                  {
                    staticClass: "export-label",
                    attrs: { slot: "label" },
                    slot: "label",
                  },
                  [
                    _vm._v("表单数据"),
                    _c("span", [_vm._v("请选择要导入的字段")]),
                  ]
                ),
              ]),
              _c(
                "el-checkbox",
                {
                  attrs: { indeterminate: _vm.isIndeterminate },
                  on: { change: _vm.handleCheckAllChange },
                  model: {
                    value: _vm.checkAll,
                    callback: function ($$v) {
                      _vm.checkAll = $$v
                    },
                    expression: "checkAll",
                  },
                },
                [_vm._v("全选")]
              ),
              _c(
                "el-checkbox-group",
                {
                  on: { change: _vm.handleCheckedChange },
                  model: {
                    value: _vm.checkedList,
                    callback: function ($$v) {
                      _vm.checkedList = $$v
                    },
                    expression: "checkedList",
                  },
                },
                _vm._l(_vm.columnList, function (item) {
                  return _c(
                    "el-checkbox",
                    {
                      key: item.__vModel__,
                      staticClass: "column-item",
                      attrs: {
                        label: item.__vModel__,
                        disabled: item.disabled,
                      },
                    },
                    [_vm._v("\n          " + _vm._s(item.label) + "\n        ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.closeDialog } }, [_vm._v("取消")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.onConfirm()
                },
              },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }